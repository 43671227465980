import {ExtensibleEntityDto} from './ExtensibleEntityDto'
import {PagedAndSortedResultRequestDto} from './PagedAndSortedResultRequestDto'
import {Guid} from 'guid-typescript';

export interface MemberShipDto extends ExtensibleEntityDto <Guid> {
  opentId: string
  nickName: string
  realName: string
  faceUrl: string
  sex: string
  phone: string
  email: string
  district: string
  address: string
}

export class GetListMemberShipDto extends PagedAndSortedResultRequestDto {
  public filter: string = ''
}
