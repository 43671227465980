


























































































import {Component, Prop, Vue, Watch} from 'vue-property-decorator'
import MemberShipApi from '@/api/member/memberShip'
import ShipCardOrderApi from '@/api/member/shipCardOrder'
import '@/plugins/vxetable'

@Component({})
export default class CardList extends Vue {
  @Prop({default: () => false}) private visible!: boolean
  @Prop({default: () => ''}) private title!: string
  @Prop({default: () => ''}) private memberShipId!: string

  private api = new MemberShipApi()
  private orderApi = new ShipCardOrderApi()
  private memberId = ''
  private tableData = new Array()

  private cards = ['次卡', '月卡', '季卡', '半年卡', '年卡', '两年卡', '家庭卡'];

  private dataForm = {
    memberShipId: '',
    shipCardType: undefined,
    second: 0,
    openCardDate: ''
  }
  private rules1 = {
    shipCardType: [
      {
        required: true,
        message: '请选择卡类型',
        trigger: 'blur'
      }
    ],
    openCardDate: [
      {
        required: true,
        message: '请选择开卡时间',
        trigger: 'blur'
      }
    ]
  }

  private rules = {
    remainingTimes: [{
      required: true,
      message: '会员卡次数不能为空',
      trigger: 'blur'
    }],
    expirationTime: [{
      required: true,
      message: '请选择延期时间',
    }]
  };

  @Watch('memberShipId', {immediate: true})
  private getMemberId(nv: string, ov: string) {
    if (nv) {
      this.memberId = nv
      this.dataForm.memberShipId = nv
    }
  }

  private async mounted() {
    await this.getList()
  }

  private async getList() {
    if (this.memberId) {
      this.tableData = await this.api.getCardList(this.memberId)
    }
  }

  private handleSubmit() {
    (this.$refs.dataForm as any).validate(async (valid: any) => {
      if (valid) {
        await this.api.makeShipCardAsync(this.dataForm)
        await this.getList()

        this.dataForm = {
          memberShipId: this.memberId,
          shipCardType: undefined,
          second: 0,
          openCardDate: ''
        }
      }
    })
  }

  private handleClose() {
    this.$emit('handleClose')
  }

  private getType(row: any) {
    if (row.cardType === 1) {
      return `次卡`
    } else if (row.cardType === 2) {
      return `月卡`
    } else if (row.cardType === 3) {
      return `季卡`
    } else if (row.cardType === 4) {
      return `半年卡`
    } else if (row.cardType === 5) {
      return `年卡`
    } else if (row.cardType === 6) {
      return `两年卡`
    } else if (row.cardType === 7) {
      return `家庭卡`
    }
  }

  private getCardTime(row: any) {
    if (row.cardState === 1) {
      if (row.cardType === 1) {
        return `剩余${row.remainingTimes}次`
      } else {
        return `${row.expirationTime}`
      }
    }
  }

  private getOpenDate(date: string) {
    if (date) {
      const newDate = new Date(date)
      return `${newDate.getFullYear()}-${(newDate.getMonth() + 1).toString().padStart(2, '0')}-${newDate.getDate().toString().padStart(2, '0')}`
    }
  }

  private handleDelete(row: any) {
    this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'error'
    }).then(async () => {
      await this.api.removeShipCard(row.id);
      await this.getList()
    }).catch(() => {
    });
  }

  private async saveEvent(row: any) {
    const $table = this.$refs.dataTable as any;
    const errMap = await $table.validate().catch((errMap: any) => errMap);
    if (!errMap) {
      if (row.cardType === 1 && !row.remainingTimes) {
        this.$message({message: '请添加次卡次数', type: 'error'});
        return false;
      }

      if (row.cardType !== 1 && !row.expirationTime) {
        this.$message({message: '请选择时间', type: 'error'});
        return false;
      }

      $table.clearActived().then(async () => {
        await this.orderApi.renewal(row.id, row.remainingTimes, row.expirationTime)
        await this.getList()
      });
    }
  }

  private cancelRowEvent(row: any) {
    const $table = this.$refs.dataTable as any;
    $table.clearActived().then(() => {
      // 还原行数据
      $table.revertData(row);
    });
  }

  private updateHandle(row: any) {
    const $table = this.$refs.dataTable as any;
    $table.setActiveRow(row);
  }
}
