import request from '@/utils/request'
import ReadOnlyAppService from '@/framework/ReadOnlyAppService'
import {ShipCardOrderDto, GetListShipCardOrderDto} from '@/framework/models/ShipCardOrder'
import {Guid} from 'guid-typescript'


export default class ShipCardOrderApi extends ReadOnlyAppService <ShipCardOrderDto, ShipCardOrderDto, Guid, GetListShipCardOrderDto> {
  constructor() {
    super(`/keep-fit/shipCardOrder`);
  }

  public async payment(id: string): Promise<any> {
    return new Promise((resolve, reject) => {
      request({
        url: `${this.url}/${id}/payment`,
        method: 'post'
      }).then((res) => resolve(res.data)).catch((error) => reject(error));
    })
  }

  public async renewal(id: string, second?: number, expirationTime?: string): Promise<any> {
    return new Promise((resolve, reject) => {
      request({
        url: `${this.url}/${id}/renewal`,
        method: 'post',
        data: {
          second,
          expirationTime
        }
      }).then((res) => resolve(res.data)).catch((error) => reject(error));
    })
  }

  public async queryOrder(id: string): Promise<any> {
    return new Promise((resolve, reject) => {
      request({
        url: `${this.url}/${id}/query-order`,
        method: 'get'
      }).then((res) => resolve(res.data)).catch((error) => reject(error));
    })
  }
}
