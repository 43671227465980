
























































import {Component, Vue} from 'vue-property-decorator';
import {PageResultDto} from '@/framework/models/ResultDto';
import {MemberShipDto, GetListMemberShipDto} from '@/framework/models/MemberShip';
import MemberShipApi from '@/api/member/memberShip';
import CardList from './components/CardList.vue'
import OpenDoorRecord from './components/OpenDoorRecord.vue'

@Component({
  components: {
    CardList,
    OpenDoorRecord
  }
})
export default class MemberIndex extends Vue {
  private api = new MemberShipApi();
  private listQuery = new GetListMemberShipDto();
  private tableData: PageResultDto<MemberShipDto> = {
    items: new Array<MemberShipDto>(),
    totalCount: 0
  };
  private loading = false

  private pagination = {
    currentPage: 1
  }

  private dialogModel = {
    openCardTitle: '',
    openCard: false,
    memberShipId: '',
    cardList: false,
    doorRecord: false
  }

  private async activated() {
    await this.getList();
  }

  private async currentChange(currentPage: number) {
    this.pagination.currentPage = currentPage
    this.listQuery.skipCount = (currentPage - 1) * this.listQuery.maxResultCount
    await this.getList()
  }

  private async getList() {
    this.tableData = await this.api.getListAsync(this.listQuery);
  }

  private handleOpenCard(row: any) {
    this.dialogModel.openCard = true
    this.dialogModel.openCardTitle = row.nickName
    this.dialogModel.memberShipId = row.id
  }

  private handleCardList(row: any) {
    this.dialogModel.cardList = true
    this.dialogModel.openCardTitle = row.nickName
    this.dialogModel.memberShipId = row.id
  }

  private handleDoorRecord(row: any) {
    this.dialogModel.doorRecord = true
    this.dialogModel.openCardTitle = row.nickName
    this.dialogModel.memberShipId = row.memberCode
  }

  private handleClose() {
    this.dialogModel.openCard = false
    this.dialogModel.cardList = false
    this.dialogModel.doorRecord = false
  }

  private async handleSync() {
    this.loading = true
    await this.api.syncMembers()
    this.loading = false
    await this.getList()
  }
};
