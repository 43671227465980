import request from '@/utils/request'
import ReadOnlyAppService from '@/framework/ReadOnlyAppService'
import {MemberShipDto, GetListMemberShipDto} from '@/framework/models/MemberShip'
import {Guid} from 'guid-typescript'

export default class MemberShipApi extends ReadOnlyAppService <MemberShipDto, MemberShipDto, Guid,
  GetListMemberShipDto> {
  constructor() {
    super(`/keep-fit/memberShip`);
  }

  public async makeShipCardAsync(dataForm: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      request({
        url: `${this.url}/make-ship-card`,
        method: 'post',
        data: dataForm
      }).then((res) => resolve(res.data)).catch((err) => reject(err));
    })
  }

  public async getCardList(memberId: string): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      request({
        url: `${this.url}/cardList`,
        method: 'get',
        params: {
          memberId
        }
      }).then((res) => resolve(res.data)).catch((err) => reject(err));
    })
  }

  public async removeShipCard(shipCardOrderId: string): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      request({
        url: `${this.url}/delete-ship-card`,
        method: 'delete',
        params: {
          id: shipCardOrderId
        }
      }).then((res) => resolve(res.data)).catch((err) => reject(err));
    })
  }

  public async syncMembers(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      request({
        url: `${this.url}/sync-members`,
        method: 'get',
      }).then((res) => resolve(res.data)).catch((err) => reject(err));
    })
  }

}
