





















import {Component, Prop, Watch, Vue} from 'vue-property-decorator'
import OpenDoorLogApi from '@/api/member/openDoorLog'

@Component({})
export default class OpenDoorRecord extends Vue {
  @Prop({default: () => false}) private visible!: boolean
  @Prop({default: () => ''}) private title!: string
  @Prop({default: () => ''}) private memberShipId!: string

  private api = new OpenDoorLogApi()

  private openDate = ''
  private tableData = []

  private tempId = ''

  @Watch('memberShipId', {immediate: true})
  private getMemberShipId(v: any) {
    if (v) {
      this.tempId = v
    }
  }

  private async mounted() {
    await this.getList()
  }

  private async getList() {
    if (this.tempId) {
      const result = await this.api.getLogAsync({filter: this.tempId, maxResultCount: 15})
      this.tableData = result.items
    }
  }

  private handleClose() {
    this.$emit('handleClose')
  }
}
